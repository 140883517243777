.SleepMachine-container {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: hsla(0, 0%, 0%, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-message {
  cursor: pointer;
  background: hsla(0, 0%, 100%, 0.1);
  border-radius: 4px;
  padding: 2rem;
  height: 150px;
  width: 150px;
  font-size: larger;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SleepMachine-header {
  display: flex;
  padding: 1rem
}

.wordmark {
  font-size: 1rem;
  margin: 0;
}

.wordmark small {
  font-weight: normal;
}

.wordmark a {
  color: var(--color-1);
  text-decoration: none;
}

.SleepMachine {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-grow: 1;
}

.viz-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.MarkovChart-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  position: relative;
}

.MarkovChart {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.MarkovChart .WeightedGraph {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.SleepMachine-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.transport-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.transport-controls input[type="number"] {
  background: transparent;
  border: none;
  font-size: larger;
  color: var(--text-color);
}

.tempo-input {
  width: 3ch;
  margin-right: 0.5rem;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  text-align: right;
}

.ProgressMeter rect {
  fill: transparent;
}

.ProgressMeter rect.on {
  fill: var(--color-1);
}

.Controls {
  display: flex;
  padding: 2rem;
}

.ProgressMeter-container {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
}

.Controls .form-col {
  display: flex;
  flex-direction: column;
}

.Controls fieldset {
  border: 0;
  padding: 0;
}

.Controls .form-row {
  display: flex;
  flex-direction: row;;
  margin-bottom: 0.5rem;
}

.RadioButtons {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  --border-radius: 4px;
  --bg-color: hsla(0, 0%, 0%, 0.4)
}

.RadioButton {
  display: flex;
  flex-grow: 1;
}

.RadioButton:first-child label { border-radius: var(--border-radius) 0 0 var(--border-radius); }
.RadioButton:last-child label { border-radius: 0 var(--border-radius) var(--border-radius) 0; }

.RadioButton label {
  background: var(--bg-color);
  border: 1px solid var(--bg-color);
  cursor: pointer;
  height: 55px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-weight: bolder;
  color: grey;
  fill: grey;
}

.RadioButton input {
  display: none;
  cursor: pointer;
}

.RadioButton input:checked + label {
  color: white;
}
.RadioButton input:checked + label svg {
  fill: white;
}

.RadioButton .waveform-icon {
  max-height: 100%;
  width: 50%;
}

.fader-bank {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.fader-container {
  display: flex;
  flex-direction: column;
}

.fader-label {
  margin-top: 1rem;
}

.PlayPauseButton {
  background: transparent;
  border: 0;
  display: block;
  outline: 0;
  cursor: pointer;

  fill: white;
  height: 100%;
}

@media screen and (orientation:portrait) {
  .SleepMachine { flex-direction: column; }
  .Controls-container {
    height: 200px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .viz-container {
    height: calc(100% - 200px);
    width: 100%;
  }

  .Controls { flex-direction: row; }
  .Controls .form-col { margin-right: 2rem; flex-shrink: 0; width: 100px; }
  .waveform .RadioButtons,
  .delay-time .RadioButtons {
    display: grid;
    grid-template-columns: 50px 50px;
    grid-template-rows: 50px 50px;
  }

  .fader-label,
  label.form-row { font-size: smaller; }
}

@media screen and (orientation:landscape) {
  .SleepMachine { flex-direction: row; }
  .Controls-container {
    height: 100%;
    width: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .viz-container {
    height: 100%;
    width: calc(100% - 300px);
  }

  .Controls {
    flex-direction: column;
  }
}

@media screen and (orientation:landscape) and (min-width: 960px) {
  .Controls-container { width: 300px; }
  .viz-container { width: calc(100% - 300px); }
}