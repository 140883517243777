.vertex {
  cursor: pointer;
}

.vertex.selected1 {
  fill: var(--current-vertex-color);
}

.vertex.selected2 {
  fill: var(--next-vertex-color);
}

.edge {
  stroke-width: 0.25;
  stroke-dasharray: 0.1 1;
}

.edge.selected {
  fill: var(--selected-edge-color);
  stroke: var(--selected-edge-color);
  stroke-dasharray: 0;
}

.edge-arrow.selected {
  fill: var(--selected-edge-color);
}

.vertex.click-target {
  fill: transparent;
}

.WeightedGraph svg {
  display: block;
  height: 100%;
  width: 100%;
  fill: var(--text-color);
}

.WeightedGraph .label {
  cursor: pointer;
}

.weight-box {
  fill: var(--bg-color);
}

[data-index="0"] .edge-drag-triangle-up,
[data-index="2"] .edge-drag-triangle-up,
[data-index="4"] .edge-drag-triangle-up,
[data-index="6"] .edge-drag-triangle-up {
  animation: float1a 1s ease-in-out infinite;
}
[data-index="0"] .edge-drag-triangle-down,
[data-index="2"] .edge-drag-triangle-down,
[data-index="4"] .edge-drag-triangle-down,
[data-index="6"] .edge-drag-triangle-down {
  animation: float1b 1s ease-in-out infinite;
}
[data-index="1"] .edge-drag-triangle-up,
[data-index="3"] .edge-drag-triangle-up,
[data-index="5"] .edge-drag-triangle-up {
  animation: float2a 1s ease-in-out infinite;
}
[data-index="1"] .edge-drag-triangle-down,
[data-index="3"] .edge-drag-triangle-down,
[data-index="5"] .edge-drag-triangle-down {
  animation: float2b 1s ease-in-out infinite;
}

@keyframes float1a {
  0% { transform: translateY(0px); }
  25% { transform: translateY(-0.25px); }
  50% { transform: translateY(0px); }
  75% { transform: translateY(0.25px); }
  100% { transform: translateY(0px); }
}

@keyframes float1b {
  0% { transform: translateY(0px); }
  25% { transform: translateY(0.25px); }
  50% { transform: translateY(0px); }
  75% { transform: translateY(-0.25px); }
  100% { transform: translateY(0px); }
}

@keyframes float2a {
  0% { transform: translateY(-0.25px); }
  25% { transform: translateY(0px); }
  50% { transform: translateY(0.25px); }
  75% { transform: translateY(0px); }
  100% { transform: translateY(-0.25px); }
}

@keyframes float2b {
  0% { transform: translateY(0.25px); }
  25% { transform: translateY(0px); }
  50% { transform: translateY(-0.25px); }
  75% { transform: translateY(0px); }
  100% { transform: translateY(0.25px); }
}

.weight-label {
  fill: var(--text-color);
}

.edge, .progress {
  stroke: var(--text-color);
}

.WeightedGraph.selected .vertex.click-target {
  stroke: transparent;
  fill: transparent;
}

.WeightedGraph.selected .vertex:not(.click-target) {
  stroke:var(--selected-vertex-color);
  fill: none;
}

.WeightedGraph.fade {
  opacity: 0.3;
}
