:root {
  --bg-color: #211a1e;
  --white: #eff6e0;
  --color-1: #daa520;
  --color-2: #3f74c3;
  --color-3: #5dbb69;
  --color-4: #c3423f;
  --text-color: var(--white);
  --current-vertex-color: var(--color-4);
  --next-vertex-color: var(--color-3);
  --selected-vertex-color: var(--color-1);
  --selected-edge-color: var(--color-1);
}

html body {
  background: var(--bg-color);
  color: var(--text-color);
}

.App {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.App-main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
